import React, { Component, Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
import { login as loginAction } from './store/authSlice'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const TermsAndConditions = React.lazy(() => import('./views/pages/login/termsAndConditions'))
const PrivacyPolicy = React.lazy(() => import('./views/pages/login/privacyPolicy'))
const Cancellation = React.lazy(() => import('./views/pages/login/cancellation'))

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    if (sessionStorage['cache']) {
      const user = JSON.parse(sessionStorage['cache'])
      dispatch(loginAction(user))
    }
  }, [])

  const isAuthenticated = useSelector((state) => (state.auth.user ? true : false))

  if (isAuthenticated) {
    console.log(`... authenticated`)
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route index path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </HashRouter>
    )
  } else {
    console.log(`... not authenticated`)
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route index name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/privacy-policy" name="Privacy Policy" element={<PrivacyPolicy />} />
            <Route exact path="/cancellation" name="Cancellation" element={<Cancellation />} />
            <Route
              exact
              path="/terms-and-conditions"
              name="Terms And Conditions"
              element={<TermsAndConditions />}
            />
          </Routes>
        </Suspense>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </HashRouter>
    )
  }
}

export default App
